<template>
  <div style="background-color: black">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 500;
                    font-size: 1.1rem;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 500;
                    font-size: 1.1rem;
                  "
                  >Per Person Price:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 500;
                    font-size: 1.1rem;
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 500;
                    font-size: 1.1rem;
                  "
                  >Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 500;
                    font-size: 1.1rem;
                  "
                  >Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1rem;
                "
                >Full Name</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1rem;
                "
                >Email Address</label
              >
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1rem;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <!-- <div
                    class="col-md-6"
                    v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                  > -->
            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-BandoReunion btn-sm my-auto"
                id=""
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 1.2rem;
                "
              >
                Redeem Code
              </button>
            </div>
            <!-- </div> -->

            <div
              class="col-md-6 mt-3"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label
                style="
                  color: black;
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1rem;
                "
                >Sizes</label
              >
              <select class="form-control" v-model="selectedSizes">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in sizes"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                color: black;
                font-family: 'Arial', sans-serif;
                font-weight: 500;
                font-size: 1rem;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-BandoReunion m-auto mr-2"
                id=""
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 1.2rem;
                "
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <!-- <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BandoReunion_V6.jpg"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: red"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  color: black;
                "
              >
                Reuniting Warriors Gathering - 2024
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: black;
                "
              >
                Saturday, October 12, 2024<br />

                3:00pm - 6:00pm
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: black;
                "
              >
                <a
                  href="https://maps.app.goo.gl/az8VpK5fMGig3ZwS6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: black"
                >
                  National Veterans Memorial and Museum
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class=""
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 500;
                font-size: 1.4em;
                color: white;
              "
            >
              The Reuniting Warriors Gathering is a celebration of the
              accomplishments and achievements of all Bando Kickboxers and Ohio
              University Boxers that were trained, instructed, and/or mentored
              by Sayagyi Sam Jones and Dr. U Maung Gyi, Chief Instructor
              Emeritus - American Bando Association / National Bando Association
              of America and Emeritus Professor - Ohio University.

              <br /><br />

              Additionally, we will host an online fundraiser for Sam Jones'
              "Boxing For Books" program, with the proceeds supporting
              textbooks, laptops, and other essential items for students and
              teachers. Please see the donation page.

              <br /><br />
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 2.2em;
                  color: white;
                "
              >
                EVENT DETAILS:
              </span>

              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1em;
                  color: white;
                "
              >
                📅 <span class="bolded"> Date:</span> Saturday, October 12,
                2024<br />
                ⏰ <span class="bolded"> Time:</span> 3:00 PM - 6:00 PM
              </span>

              <br /><br />
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 2.2em;
                  color: white;
                "
              >
                LOCATION:
              </span>

              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1em;
                  color: white;
                "
              >
                <a
                  href="https://maps.app.goo.gl/az8VpK5fMGig3ZwS6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  • National Veterans Memorial Museum, 300 West Broad St.,
                  Columbus, OH, 43215. </a
                ><br />

                • This is an indoor event.<br />

                • Security on site.
              </span>

              <br />
              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 2.2em;
                  color: white;
                "
              >
                TICKETS:
              </span>

              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1em;
                  color: white;
                "
              >
                • General Admission Ticket - $125.00 - Before Oct. 6th.<br />
                • General Admission Ticket Price - $145.00 - After Oct. 6th.<br />
                • Limited Capacity.<br />
                • NO TICKETS SOLD AT THE DOOR.<br />
                • Must have ticket to attend.
              </span>

              <br />
              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 2.2em;
                  color: white;
                "
              >
                ATTIRE:
              </span>

              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1em;
                  color: white;
                "
              >
                • Casual.
              </span>

              <br />
              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 2.2em;
                  color: white;
                "
              >
                FOOD:
              </span>

              <br />
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1em;
                  color: white;
                "
              >
                • Appetizers will be served during the event.
              </span>

              <br />
              <br />

              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 2.2em;
                  color: white;
                "
              >
                DISCLAIMER:
              </span>

              <br />
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 1em;
                  color: white;
                "
              >
                • NO OUTSIDE FOOD OR DRINK.<br />
                • MUST HAVE TICKET TO ENTER.<br />
                • NO WEAPONS.<br />
                • NO REFUNDS.
              </span>

              <br />
            </p>
          </div>
          <!-- <hr /> -->

          <!-- <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                While we will be busy taking care of important alumni business,
                we will still take time to enjoy our guest speakers, the event
                space, a jazz trio (Copacetic | JazzColumbus.com ), an excursion
                to Crossroads Plaza (owned by the Tuskegee Alumni Housing
                Foundation), each other, and conclude the conference with the
                Crimson & Gold Banquet. The conference will be informative,
                inspiring, engaging, and fun!
                <br />
                <br />
                <span
                  class="bolded"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 22px;
                  "
                  >Theme:</span
                >
                Remembering our Greatness and Celebrating our Excellence
                <br />
                <br />
                Conference Purpose: To be educational, Tuskegee University
                information sharing, collaborative, and a scholarship fundraiser
                <br />
                <br />
              </p>
            </div>
          </div>-->
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                  color: white;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white text-center"
                  style="
                    background-color: red;
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 1.1rem;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    style="
                      color: black;
                      font-family: 'Arial', sans-serif;
                      font-weight: 500;
                      font-size: 1rem;
                    "
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p
                      style="
                        color: black;
                        font-family: 'Arial', sans-serif;
                        font-weight: 700;
                        font-size: 1.1rem;
                      "
                    >
                      Total Price: ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-BandoReunion"
                      id=""
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-weight: 700;
                        font-size: 1.2rem;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <!-- <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                  color: white;
                "
              >
                T-SHIRT
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in shirtTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white text-center"
                  style="
                    background-color: red;
                    color: black;
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 1.1rem;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    style="
                      color: black;
                      font-family: 'Arial', sans-serif;
                      font-weight: 500;
                      font-size: 1rem;
                    "
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p
                      style="
                        color: black;
                        font-family: 'Arial', sans-serif;
                        font-weight: 700;
                        font-size: 1.1rem;
                      "
                    >
                      Total Price: ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-BandoReunion"
                      id=""
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-weight: 700;
                        font-size: 1.2rem;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Purchase"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Tickets Section starts -->
      <!-- <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                VENDOR TABLE
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in vendorTableoptions(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                  color: white;
                "
              >
                Fundraiser: “Boxing For Books”
              </h3>
            </div>

            <div class="section-heading mt-3">
              <div class="text-center">
                <a
                  href="https://myle.com/reuniting-warriors-gathering-2024-donations"
                  class="btn solid-btn-BandoReunion mb-1 text-center"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 1.2rem;
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                  >Donate Now</a
                >
              </div>
            </div>
            <br /><br />
            <div class="text-center">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_flyer_bando_reunion_V5.jpg"
                alt="Slow Jam Social Event"
                class="w-50 img-fluid"
              />
            </div>
            <!-- <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in groupTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Donate"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <hr style="background-color: #5b5b5b" />
    <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12">
        <div class="mt-4">
          <div class="section-heading">
            <h1
              class="text-center"
              style="
                font-family: 'nunito-bold', sans-serif;
                font-size: 2.4em;
                color: white;
              "
            >
              Venue
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between lessSideMargin"
      style="margin: auto"
    >
      <div class="col-md-12">
        <div class="text-center">
          <p
            class="w-auto d-inline-block mb-2"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: bold;
              font-size: 2.3em;
              color: #e28332;
            "
          >
            <a
              href="https://maps.app.goo.gl/az8VpK5fMGig3ZwS6"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #ffffff"
            >
              National Veterans Memorial and Museum
            </a>
          </p>

          <p
            class=""
            style="
              font-family: 'nunito', sans-serif;
              font-size: 1.4em;
              color: white;
            "
          >
            <span class="bolded"> Address:</span>

            <a
              href="https://maps.app.goo.gl/az8VpK5fMGig3ZwS6"
              target="_blank"
              rel="noopener noreferrer"
              style="color: white"
            >
              300 W Broad St, Columbus, OH 43215
            </a>
          </p>
        </div>

        <div class="row mt-2">
          <div class="col-md-3 col-md-3"></div>

          <div class="col-md-6 col-md-6">
            <div class="text-center">
              <a
                href="https://maps.app.goo.gl/az8VpK5fMGig3ZwS6"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #e28332"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_venue_NVMM.jpeg"
                  class="w-75 img-fluid"
                  style="border-radius: 3%"
                />
              </a>
            </div>
          </div>
          <div class="col-md-3 col-md-3"></div>
        </div>
      </div>
    </div>

    <hr style="background-color: #5b5b5b" />
    <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12">
        <div class="mt-4">
          <div class="section-heading">
            <h1
              class="text-center"
              style="
                font-family: 'nunito-bold', sans-serif;
                font-size: 2.4em;
                color: white;
              "
            >
              Hotel
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between lessSideMargin"
      style="margin: auto"
    >
      <div class="col-md-12">
        <div class="text-center">
          <p
            class="w-auto d-inline-block mb-2"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: bold;
              font-size: 2.3em;
              color: #e28332;
            "
          >
            <a
              href="https://www.hyatt.com/en-US/group-booking/CMHRC/G-LCDG"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #ffffff"
            >
              Hyatt Regency Columbus
            </a>
          </p>

          <p
            class=""
            style="
              font-family: 'nunito', sans-serif;
              font-size: 1.4em;
              color: white;
            "
          >
            <span class="bolded"> Address:</span>

            <a
              href="https://www.hyatt.com/en-US/group-booking/CMHRC/G-LCDG"
              target="_blank"
              rel="noopener noreferrer"
              style="color: white"
            >
              350 North High Street Columbus, Ohio 43215
            </a>
          </p>
        </div>

        <div class="row mt-2">
          <div class="col-md-3 col-md-3"></div>

          <div class="col-md-6 col-md-6">
            <div class="text-center">
              <a
                href="https://www.hyatt.com/en-US/group-booking/CMHRC/G-LCDG"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #e28332"
              >
                <img
                  src="https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2017/09/21/0944/Hyatt-Regency-Columbus-P025-Exterior.jpg/Hyatt-Regency-Columbus-P025-Exterior.16x9.jpg?imwidth=640"
                  class="w-75 img-fluid"
                  style="border-radius: 3%"
                />
              </a>
            </div>
            <div class="text-center">
              <a
                href="https://www.hyatt.com/en-US/group-booking/CMHRC/G-LCDG"
                class="btn solid-btn-BandoReunion mt-3 text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
                target="_self"
                >Reserve Now</a
              >
            </div>
          </div>
          <div class="col-md-3 col-md-3"></div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="section-heading mb-3 mt-5">
      <div class="text-center">
        <a
          href="https://myle.com/reuniting-warriors-gathering-2024-collection"
          class="btn solid-btn-BandoReunion mb-1 ml-3 mr-3 text-center"
          style="
            font-family: 'Arial', sans-serif;
            font-weight: 700;
            font-size: 1.2rem;
          "
          target="_self"
          >Back To Main Page</a
        >

        <a
          href="https://myle.com/reuniting-warriors-gathering-2024-donations"
          class="btn solid-btn-BandoReunion ml-3 mr-3 mb-1 text-center"
          style="
            font-family: 'Arial', sans-serif;
            font-weight: 700;
            font-size: 1.2rem;
          "
          target="_blank"
          rel="noopener noreferrer"
          >Donations</a
        >
      </div>
    </div>

    <!-- <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/north_central_mocks.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mt-0 mb-2"
          style="
            font-family: 'Arial', sans-serif;
            font-weight: 700;
            font-size: 24px;
          "
        >
          Get ready for The 58th North Central Region Conference with MYLE
        </p>

        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Arial', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
        >
          For your convenience, we have partnered with MYLE as our ticket
          provider. MYLE - Make Your Life Entertaining is a Skegee Alumni
          Co-Owned Event and Entertainment platform. Learn more today:
          <br /><br />
          • Buy your event tickets with MYLE
          <br />
          • Tickets are available on the app in the Accounts/Tickets section for
          easy access including Apple Wallet
          <br />
          • Help the Columbus Alumni Chapter earn referral points – use referral
          code “CTAC” during account set-up
          <br />
          • Receive important updates before and during the event
          <br />
          • Get around Columbus seamlessly using the MYLE app for transportation
          options including Waze/Apple Maps/Google Maps/Uber and Lyft –
          integrated into MYLE mobile.
          <br />
          • Find other fun things to do in Columbus or on the way!
          <br />
          <br />
          We look forward to seeing you soon.
          <br />
          #JustMYLEit and #makeyourlifeentertaining
          <br />
        </p>
      </div>
    </div> -->

    <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12 mt-5">
        <div class="mt-3">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 500;
                font-size: 15px;
                color: white;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
import confirmationPopup from "../../components/confirmationPopup";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      showsuccess: false,
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      vlist: ["tk_3Dtz1WocLznFaQB"],
      vendorlist: ["tk_WYo4VS5DGSY6RJX"],
      shirtTicketslist: [
        // T SHIRT
        "tk_3Dtz1WocLznFaQB",
      ],
      individualsData: ["tk_GEDgWqKqfLLrUzP"],
      groupData: [
        //DONATIONS
        "tk_OoJRPnnDvgFIP48",
        "tk_QXiQu79kemz4FFJ",
        "tk_9r8HkTCJU9atl9H",
        "tk_PjmMPXWWNLyV3jQ",
        "tk_uaHHYKiibLByVt3",
        "tk_Xg662lBvroYj49l",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_GEDgWqKqfLLrUzP: {
          messages: ["Ticket Price: $125", "Processing fee of $10.03"],
        },

        tk_OoJRPnnDvgFIP48: {
          messages: ["Ticket Price: $25"],
        },
        tk_QXiQu79kemz4FFJ: {
          messages: ["Ticket Price: $50"],
        },
        tk_9r8HkTCJU9atl9H: {
          messages: ["Ticket Price: $100"],
        },
        tk_PjmMPXWWNLyV3jQ: {
          messages: ["Ticket Price: $250"],
        },
        tk_uaHHYKiibLByVt3: {
          messages: ["Ticket Price: $500"],
        },
        tk_Xg662lBvroYj49l: {
          messages: ["Ticket Price: $1000"],
        },

        tk_3Dtz1WocLznFaQB: {
          messages: ["Ticket Price: $30", "Processing fee of $3.65"],
        },
      },

      priceIds: {
        tk_GEDgWqKqfLLrUzP: "price_1Pj2OvD0vTZ4QB9cfconsF1u",
        tk_OoJRPnnDvgFIP48: "price_1PhzahD0vTZ4QB9cRoAX2end",
        tk_QXiQu79kemz4FFJ: "price_1PhzaaD0vTZ4QB9c2wkc9jcG",
        tk_9r8HkTCJU9atl9H: "price_1PhzaqD0vTZ4QB9cm4KtVO5u",
        tk_PjmMPXWWNLyV3jQ: "price_1PhzazD0vTZ4QB9cKBGJrk97",
        tk_uaHHYKiibLByVt3: "price_1Phzb7D0vTZ4QB9ce1euEbXK",
        tk_Xg662lBvroYj49l: "price_1PhzbFD0vTZ4QB9cuEY0jmYd",
        tk_3Dtz1WocLznFaQB: "price_1PWfZiD0vTZ4QB9cjNq9I4rP",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    VueElementLoading,
    confirmationPopup,
    StripeCheckout,
  },

  methods: {
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    shirtTick(list) {
      return list.filter((t) => this.shirtTicketslist.includes(t.ticketCode));
    },
    vendorTableoptions(list) {
      return list.filter((t) => this.vendorlist.includes(t.ticketCode));
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };
      if (vm.vlist.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo = {
          shirtSize: this.selectedSizes,
        };
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/requested_tickets",
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredReunitingWarriorsGathering2024Tickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_GEDgWqKqfLLrUzP: null,
            tk_OoJRPnnDvgFIP48: null,
            tk_QXiQu79kemz4FFJ: null,
            tk_9r8HkTCJU9atl9H: null,
            tk_PjmMPXWWNLyV3jQ: null,
            tk_uaHHYKiibLByVt3: null,
            tk_Xg662lBvroYj49l: null,
            tk_3Dtz1WocLznFaQB: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    this.fetchAllTickets();
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Reuniting Warriors Gathering 2024",

    meta: [
      {
        name: "description",
        content: "MYLE - Reuniting Warriors Gathering 2024",
      },

      {
        name: "keywords",
        content: "MYLE - Reuniting Warriors Gathering 2024",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/reuniting-warriors-gathering-2024",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
