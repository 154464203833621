<template>
  <div>
    <div class="w-100">
      <!-- <b class="font-weight-bold">Total Seating Per Person</b> <br /> -->
      <p
        class="mb-2 mt-3"
        style="
          font-family: 'nunito', sans-serif;
          font-weight: 500;
          font-size: 1.1em;
          color: black;
        "
      >
        Available Seating (Max Selection: {{ limit }})
      </p>
    </div>
    <div class="d-flex w-100">
      <div class="selectionPane">
        <p
          :key="'a' + i"
          :class="{ recselected: seating.selected }"
          v-for="(seating, i) in nonSelected || []"
          @click="addToSelection(seating)"
          style="
            font-family: 'nunito', sans-serif;
            font-weight: 500;
            font-size: 1em;
            color: black;
          "
        >
          {{ seating.alias }}
        </p>
      </div>
      <div class="midPane">
        <input
          type="button"
          @click="selection()"
          class="btn selectionButton w-100"
          value=">>"
          style="color: #a32035"
        />

        <input
          type="button"
          @click="deselection()"
          class="btn selectionButton w-100"
          value="<<"
          style="color: #a32035"
        />
      </div>
      <div class="selectionPane">
        <p
          :key="'a' + j"
          :class="{ recselected: !seating.selected }"
          v-for="(seating, j) in selected"
          @click="addTodeSelection(seating)"
          style="
            font-family: 'nunito', sans-serif;
            font-weight: 500;
            font-size: 1em;
            color: black;
          "
        >
          {{ seating.alias }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "doubleList",
  emits: ["listtopush"],
  data() {
    return {
      selected: [],
      selectedList: [],
      deselectedList: [],
      nonSelected: [],
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    addToSelection(rec) {
      const curr = [
        ...this.nonSelected.filter((r) => r.selected),
        ...this.selected,
      ];
      if (this.limit > curr.length) {
        rec.selected = true;
        this.nonSelected = [...this.nonSelected];
      } else {
        rec.selected = false;
        this.nonSelected = [...this.nonSelected];
      }
    },
    selection() {
      this.selected = [
        ...this.nonSelected.filter((r) => r.selected),
        ...this.selected,
      ];
      this.nonSelected = this.nonSelected.filter((r) => !r.selected);
      this.$emit("listtopush", this.selected);
    },
    deselection() {
      this.nonSelected = [
        ...this.selected.filter((r) => !r.selected),
        ...this.nonSelected,
      ];
      this.selected = this.selected.filter((r) => r.selected);
      this.$emit("listtopush", this.selected);
    },
    addTodeSelection(rec) {
      rec.selected = false;
      this.selected = [...this.selected];
    },
  },
  mounted() {
    this.nonSelected = [...this.list];
  },
  watch: {
    list() {
      const dict = {};
      this.selected.forEach((e) => {
        dict[e.alias] = true;
      });
      console.log(dict);
      this.nonSelected = [...this.list.filter((r) => !dict[r.alias])];
    },
  },
};
</script>

<style scoped>
.selectionPane {
  border: 1px solid #e5dede;
  width: 45%;
  height: 10rem;
  overflow: auto;
  display: inline-block;
  border-style: outset;
}

.selectionPane p {
  border: 1px solid #e5dede;
  padding: 5px 10px;
  margin: 0px;
  cursor: pointer;
}

.midPane {
  width: 10%;
  height: 10rem;
  display: inline-block;
  padding-top: 2.5rem;
  text-align: center;
}

.selectionButton {
  padding: 5px 7px;
  font-weight: bolder;
  cursor: pointer;
}

.recselected {
  background: #eba900;
  color: white;
}
</style>
