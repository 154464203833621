<template>
  <div>
    <nu-modal ref="termsModal" title="">
      <div class="container-fluid">
        <div class="row">
          <!-- Ticket Cart -->

          <div
            class="row align-items-center justify-content-between"
            style="margin: auto; width: 100% !important"
          >
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'FFMetaWebPro', sans-serif;
                    font-weight: 700;
                    font-size: 3em;
                    color: #202c5d;
                  "
                >
                  Terms and Condition
                </span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div style="height: auto; overflow: auto">
                <p
                  class="mb-1 mt-3"
                  style="
                    font-family: 'FFMetaWebPro', sans-serif;
                    font-size: 1.2em;
                    color: #555555;
                    width: 100%;
                  "
                >
                  Cardmember gift is available to select primary Cardmembers
                  until registration supplies are exhausted or November 25, 2024
                  (whichever occurs first). The gift is limited and available on
                  a first-come, first-served basis. Must be a Wyndham
                  Rewards<sup>®</sup> Visa<sup>®</sup> Cardmember or Wyndham
                  Rewards Earner<sup>®</sup> Cardmember to secure benefits.
                  There is no cost associated with claiming this gift.
                  Registration entitles Cardmember to one (1) Winter Gift Box.
                  Limit one (1) Winter Gift Box. per primary Cardmember. The
                  Winter Gift Box will be shipped approximately two (2) weeks
                  after registration closes and will arrive on or about December
                  17, 2024. By registering for this experience, you are giving
                  Wyndham Rewards permission to provide your name, address,
                  email, and telephone number to third party vendors, partners
                  (e.g., Vega Group LLC), Barclays, and shipping services (i.e.,
                  FedEx, UPS, etc.). Each of Visa U.S.A. Inc, Visa International
                  Service Association and Wyndham Rewards reserves the right to
                  modify or cancel this offer at any time upon notice to
                  invitees. Contents of the Winter Gift Box may vary from the
                  description due to product availability. Schedules and
                  staffing are subject to change. Kits are not for resale. Void
                  where prohibited. Winter Gift Box will be delivered directly
                  to the address provided at the time of registration. Delivery
                  area is limited to the United States, excluding Alaska,
                  Hawaii, and all U.S. territories and possessions.

                  <br /><br />

                  <span class="bolded" style="font-size: 1.2em">
                    Limitation of Liability:
                  </span>
                  Participant agrees to comply with all applicable regulations
                  with respect to the offer. In redeeming this offer,
                  participant, on behalf of himself/herself and his/her
                  immediate family members (spouse, parents, children and
                  siblings and their spouses) and individuals living in the same
                  households of such participants, whether or not related,
                  agrees to release and hold harmless (i) Wyndham Rewards Inc.,
                  Vega Group LLC, Visa U.S.A. Inc., Visa International Service
                  Association; and (ii) the parents, subsidiaries, successors,
                  affiliates, related companies, client financial institutions,
                  premium gift suppliers, and advertising, promotion and
                  marketing agencies of each of the foregoing entities; and
                  (iii) the officers, directors, employees and agents of each
                  entity described in the foregoing clauses (i) and (ii); and
                  (iv) the successors and assigns of all parties described in
                  the foregoing clauses (i) through (iii) from any and all
                  liability or damage of any kind (including personal injury)
                  resulting or arising from participation in this offer and/or
                  acceptance, possession, use, misuse or nonuse of this offer
                  (including any travel or travel-related activity thereto).

                  <br /><br />

                  The Wyndham Rewards Earner<sup>®</sup> Cards are issued by
                  Barclays Bank Delaware (Barclays) pursuant to a license by
                  Visa U.S.A. Inc. Visa and Visa Signature are registered
                  trademarks and used under license from Visa U.S.A. Inc.

                  <br />

                  © 2024 Barclays Bank Delaware, PO Box 8801, Wilmington, DE
                  19801, Member FDIC.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
          <div class="col-md-12" id="banner">
            <img
              class="align-middle"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
              style="
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-top: 4.2rem;
              "
            />
          </div>
        </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section mt-5" style="background: #202c5d">
        <div class="col-lg-12 col-md-12 mt-5">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'FFMetaWebPro', sans-serif;
                  font-weight: 700;
                  font-size: 2.8em;
                  color: white;
                "
              >
                FREQUENTLY ASKED QUESTIONS
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="lead-heading mb-2"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: bold;
              font-size: 2em;
              color: #202c5d;
            "
          >
            Q: When will you ship my Winter Cookie Kit?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            All gifts will be shipped the week of Monday, December 2, 2024 to
            the address provided during registration.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: bold;
              font-size: 2em;
              color: #202c5d;
            "
          >
            Q: When will I receive my Winter Cookie Kit?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Gift boxes are scheduled to arrive before December 17, 2024

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: bold;
              font-size: 2em;
              color: #202c5d;
            "
          >
            Q: What will I need to make the Sugar Cookies?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            <!-- Ingredients: <br />
            <span class="ml-5"> • TBD </span><br /> -->
            Supplies: <br />
            <span class="ml-5"> • Water </span><br />
            <span class="ml-5"> • Measuring Cup </span><br />
            <span class="ml-5"> • Mixing Bowl </span><br />
            <span class="ml-5"> • Roller </span><br />
            <span class="ml-5"> • Parchment Paper </span><br />
            <span class="ml-5"> • Baking Sheet </span>

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: bold;
              font-size: 2em;
              color: #202c5d;
            "
          >
            Q: How do I make my Sugar Cookies?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            1. Remove all paper, plastic and packaging from the sugar cookie
            kit. <br />
            2. Place cookie mix in mixing bowl. <br />
            3. Add 1.1 oz of water to the mix. <br />
            4. Using a mixer or spatula, mix thoroughly until dough forms around
            the paddle. <br />
            5. Turn dough out onto a lightly floured surface. <br />
            6. Roll dough until it is ¼ inch thick.<br />
            7. Cut with cookie cutter.<br />
            8. Place onto a greased or paper lined baking sheet. <br />
            9. Bake as directed: <br />
            <span class="ml-5">
              - Conventional Oven: Preheat oven to 375 degrees and bake for 9 -
              11 minutes.
            </span>
            <br />
            <span class="ml-5">
              - Convention Oven: Preheat oven to 325 degrees and bake for 5 - 7
              minutes.
            </span>
            <br />

            10. Remove cookies from oven and cool. <br />
            11. Serve and enjoy!

            <!-- • Once you have emptied your gift box and removed all paper, plastic
            and packaging from the sugar cookie kit and spatula, please do the
            following:<br />
            • Take your sugar cookie kit inclusions out of the box and be sure
            to read all safety instructions/directions before first use.<br />
            • Make the batter by following the directions on the mix package.<br />
            • Use the potholder (provided) to remove baking sheet from the oven.
            <br />
            • Use the spatula (provided) to remove the cookies from the baking
            sheet.<br />
            • Allow the cookies to cool, decorate, and ENJOY! -->

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: bold;
              font-size: 2em;
              color: #202c5d;
            "
          >
            Q: Who can I contact if I have any questions?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Please email

            <a
              href="mailto:wyndhamRewards@vegagroup.com"
              style="color: #318ac7"
            >
              WyndhamRewards@vegagroup.com </a
            >with any questions or concerns.

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: bold;
              font-size: 2em;
              color: #202c5d;
            "
          >
            Q: What are the terms and conditions for this experience?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            To see the full Terms and Conditions for this offer
            <a
              style="color: #318ac7; cursor: pointer"
              target="_self"
              rel="noopener noreferrer"
              @click="openTermsModel()"
            >
              click here</a
            >.

            <br />
          </p>

          <p
            class="text-center mt-5 mb-4"
            style="
              font-family: 'FFMetaWebPro', sans-serif;
              font-weight: 400;
              font-size: 1em;
              color: black;
            "
          >
            The Wyndham Rewards<sup>®</sup> Visa<sup>®</sup> Cards are issued by
            Barclays Bank Delaware pursuant to a license from Visa U.S.A Inc.
            <br />
            Visa and Visa Signature are registered trademarks and used under
            license from Visa U.S.A. Inc.
            <br />
            © 2024 Barclays Bank Delaware, PO Box 8801, Wilmington, DE 19801,
            Member FDIC.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/Navbar5";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
  },
  methods: {
    openTermsModel: function () {
      this.$refs.termsModal.open();
    },

    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons7/");
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Wyndham Rewards® Visa® Winter Gift Box FAQs",

    meta: [
      {
        name: "description",
        content: "Wyndham Rewards® Visa® Winter Gift Box FAQs",
      },

      {
        name: "keywords",
        content: "Wyndham Rewards® Visa® Winter Gift Box FAQs",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/wyndham-rewards-visa-winter-gift-box-faqs",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}

.bolded {
  font-weight: bold;
}
</style>
